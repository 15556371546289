<template>
  <div class="download">
    <div class="downloadList">
      <dl class="ios">
        <dt>
          <img src="../assets/imgs/download/download_ios_qr.png" alt="" />
        </dt>
        <dd>iOS版</dd>
        <dd>
          <div>
            扫码下载
          </div>
        </dd>
      </dl>
      <dl class="android" @mouseenter="showQRCli" @mouseleave="hideQRCli">
        <dt>
          <img src="../assets/imgs/download/download_android_qr.png" alt="" />
        </dt>
        <dd>Android版</dd>
        <dd>
          <div>
            扫码下载
          </div>
        </dd>
      </dl>
      <dl class="mac" @mouseenter="showQRCli" @mouseleave="hideQRCli">
        <dt>
          <img src="../assets/imgs/download/download_mac_img.png" alt="" />
        </dt>
        <dd>Mac版</dd>
        <dd>
          <div>
            点击下载
          </div>
        </dd>
      </dl>
      <dl class="windows" @mouseenter="showQRCli" @mouseleave="hideQRCli">
        <dt>
          <img src="../assets/imgs/download/download_windows_img.png" alt="" />
        </dt>
        <dd>Windows版</dd>
        <dd>
          <div>
            点击下载
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Download",
  setup() {
    return {
    };
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.download {
  text-align: center;
  padding-bottom: 96px;

  .downloadList {
    position: relative;
  }

  h3 {
    text-align: center;
    font-size: 36px;
    font-weight: normal;
    color: #000;
    line-height: 107px;
    margin-block: 0;
    margin-bottom: 30px;
  }

  dl {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 384px;
    height: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 71px 0px rgba(233, 237, 244, 1);
    border-radius: 11px;
    margin-right: 45px;

    img {
      width: 188px;
    }

    dd:nth-of-type(1) {
      color: #000000;
      font-size: 36px;
      line-height: 107px;
    }

    dd:nth-of-type(2) div {
      width: 206px;
      height: 62px;
      border-radius: 5px;
      line-height: 62px;
      text-align: center;
      color: #5679f9;
      font-size: 25px;
    }
  }
}
</style>
